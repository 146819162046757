.frame {
  /* align-items: flex-start; */
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 10px;
  height: 100%;
  min-height: 509px;
  min-width: 375px;
  position: relative;
  width: 100%;
}

.frame .group {
  background-color: #ffffff;
  box-shadow: 4px 0px 14px #00000040;
  height: 509px;
  position: relative;
  width: 375px;
}

.frame .line {
  height: 7px;
  left: 138px;
  position: absolute;
  top: 17px;
  width: 98px;
}

.frame .group-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 28px;
  padding: 20px 10px;
  width: 100%;
  height: 100%;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.frame .group-2 {
  height: 399px;
  position: relative;
  width: 325px;
}

.frame .button {
  /* background-color: #09132d !important; */
  display: flex !important;
  padding: 14px 100px !important;
  width: 299px !important;
}

.frame .type-primary-state-normal-outline-false-link-false {
  font-family: "Poppins", Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
}

.frame .div-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
}

.frame .input-field-instance {
  flex: 0 0 auto !important;
  width: 325px !important;
}

.frame .design-component-instance-node {
  align-self: unset !important;
  color: var(--darkdark-500) !important;
  font-family: "Poppins", Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  white-space: nowrap !important;
  width: fit-content !important;
}

.frame .input-field-2 {
  align-self: stretch !important;
  background-color: var(--whitewhite-200) !important;
  border: 1px solid !important;
  border-color: #b2b2b2 !important;
  border-radius: 4px !important;
  flex: 0 0 auto !important;
  justify-content: unset !important;
  overflow: hidden !important;
  padding: 20px !important;
  width: 100% !important;
}

.frame .input-field-3 {
  color: #b2b2b2 !important;
  font-family: "Poppins", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 19.2px !important;
}

.frame .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  position: relative;
}

.frame .input-field-4 {
  margin-right: -18px !important;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: white;
  transition: ease-in-out;
}

.loader {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 7.5px solid;
  border-color: rgba(9, 19, 45, 0.15) rgba(9, 19, 45, 0.25) rgba(9, 19, 45, 0.35) rgba(9, 19, 45, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader.small {
  width: 24px;
  height: 24px;
  border-width: 5px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
