.beneficiary {
  display: flex;
  flex-direction: column;
  width: 325px !important;
}

.beneficiary-header {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 176px;
  display: inline-flex;
  margin-bottom: 10px;
}

.beneficiary-header-text {
  color: black;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
}

.beneficiary-header-view-all {
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-family: Poppins;
}

.beneficiary-header-view-all:hover,.beneficiary-header-view-all:focus {
  background-color: #ebebeb;
}

.beneficiary-list {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  display: inline-flex;
  overflow-x: auto;
  overflow-y: clip;
  -ms-overflow-style: none;
  scrollbar-width: none
}

.beneficiary-list::-webkit-scrollbar {
  display: none;
}

.beneficiary-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  width: 63px;
  cursor: pointer;
  padding: 3px;
  border-radius: 2px;
}

.beneficiary-item.active .beneficiary-avatar-bg {
  background: var(--button-primary);
}

.beneficiary-item.active .beneficiary-title {
  color: var(--button-primary);
}

.beneficiary-avatar {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.beneficiary-avatar-bg {
  width: 52px;
  height: 52px;
  background: var(--darkdark-200);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beneficiary-avatar-initials {
  color: white;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.beneficiary-title {
  text-align: center;
  color: var(--darkdark-200);
  font-size: 11px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 14.40px;
  word-wrap: anywhere;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.beneficiary-screen-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 375px;
}

.beneficiary-screen-back {
  position: absolute;
  left: calc(50vw - (375px / 2));
  cursor: pointer;
  display: flex;
}

.beneficiary-screen-title {
  color: #09132D;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.10px;
  word-wrap: break-word;
  padding: 20px;
}

.beneficiary-screen-search {
  padding: 20px 0;
}

.beneficiary-screen-search input {
  padding: 10px 20px;
  width: 100%;
  border-radius: 10px;
  background: #EBEBEB;
  border: none;
  outline: none;
  color: #9C9C9C;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.28px;
}

.beneficiary-screen-item {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  padding: 5px 0;
  cursor: pointer;
  width: calc(100% + 65px);
  overflow: hidden;
}

.beneficiary-screen-container {
  display: flex;
  width: 100%;
  transition: transform .8s;
}

.beneficiary-screen-container.translate {
  transform: translateX(-65px);
}

.beneficiary-screen-item-inner {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  width: calc(100% - 65px);
}

.beneficiary-screen-item-inner .option {
  margin-left: auto;
  align-self: center;
  padding: 10px;
  transform: rotateZ(90deg);
  font-weight: bolder;
}

.beneficiary-screen-container .delete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.beneficiary-screen-container .delete .delete-icon-bg {
  width: 65px;
  height: 54px;
  background-color: #FF0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beneficiary-screen-item-details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex
}

.beneficiary-screen-item-name {
  color: black;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.30px;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.beneficiary-screen-item-highlight {
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  margin-bottom: 10px;
}

.beneficiary-screen-item-key {
  color: #454545;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}

.beneficiary-screen-item-value {
  color: #9C9C9C;
  font-size: 10px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.20px;
  word-wrap: break-word;
}

.beneficiary-screen-item-divider {
  width: 342px;
  height: 0px;
  border: 1px #D5D5D5 solid;
}

.beneficiary-screen-list {
  height: calc(100vh - (81px + 20px + 58px));
  overflow: auto;
}

.beneficiary-avatar-bg.blue {
  background: #09132D;
}

.beneficiary-avatar-bg.green {
  background: #092D0F;
}

.beneficiary-avatar-bg.red {
  background: #2D0914;
}

.beneficiary-info-wrapper {
  padding: 20px 0;
}

.beneficiary-info-item {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  padding: 5px 0;
}

.beneficiary-info-item-inner {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  width: 100%;
  border: 1px solid #b2b2b2 !important;
  border-radius: 11px !important;
  padding: 10px;
}

.beneficiary-info-item-details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
  width: 100%;
}

.beneficiary-info-item-name {
  color: black;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.30px;
  word-wrap: break-word;
  margin-bottom: 10px;
  display: flex;
  align-items: space-between;
  width: 100%;
  justify-content: space-between;
}

.beneficiary-info-item-cancel {
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.beneficiary-info-item-cancel:hover {
  background-color: #ebebeb;
}

.beneficiary-info-item-highlight {
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  margin: 5px 0;
  width: 100%;
}

.beneficiary-info-item-key {
  color: #454545;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}

.beneficiary-info-item-value {
  color: black;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.20px;
  word-wrap: break-word;
  float: right;
}

.beneficiary-info-item-divider {
  width: 80%;
  height: 0px;
  border: 1px #D5D5D5 solid;
}