.base-button {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  height: 24px;
}

.base-button .icon-instance-node {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.base-button .button-text {
  color: var(--whitewhite-200);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
