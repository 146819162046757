.authorization {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 30px;
}

.authorization .header {
  font-size: 16px;
  font-weight: 500;
}

.authorization .message {
  font-size: 14px;
  line-height: 1.3;
}

.authorization strong {
  font-weight: 600;
}

.authorization .check-icon {
  margin-bottom: 7px;
}

.authorization .agree {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.authorization .agree input {
  width: 20px;
  height: 20px;
  accent-color: var(--button-primary);
}

.authorization-cover .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}