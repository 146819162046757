.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.modal .backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.modal .content {
  border-radius: 15px;
  background: #FFF;
  box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075),
    0 16px 16px hsl(0deg 0% 0% / 0.075);
}

.modal .content .body {
  padding: 28px;
}