.fin-literacy .icon .check-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  box-sizing: content-box;
  position: relative;
  top: 60px;
}

.fin-literacy .header-bg {
  background: #09132D;
  margin: -28px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 100px;
  margin-bottom: 60px;
}

.fin-literacy {
  max-width: 320px;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 30px;
}

.fin-literacy .message {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fin-literacy .message .header {
  font-size: 16px;
  font-weight: 500;
}

.fin-literacy .message .text {
  font-size: 14px;
  line-height: 1.3;
}

.fin-literacy strong {
  font-weight: 600;
}

.fin-literacy-cover .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}