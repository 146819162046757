.type-primary-state {
  align-items: center;
  background-color: var(--button-primary);
  border: 1px solid var(--button-primary);
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.type-primary-state.outline {
  align-items: center;
  color: var(--button-primary);
  background-color: unset;
}

.type-primary-state.outline .button-text {
  color: var(--button-primary) !important;
}

.type-primary-state .base-button-instance {
  flex: 0 0 auto !important;
}

.type-primary-state.disabled {
  background-color: var(--darkdark-200) !important;
  border-color: var(--darkdark-200) !important;
  cursor: not-allowed !important;
}
