.success-modal-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-modal .icon .check-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  width: 80px;
  height: 80px;
}

.success-modal .header-bg {
  margin-bottom: 20px;
}

.success-modal {
  max-width: 320px;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 30px;
}

.success-modal .message {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.success-modal .message .header {
  font-size: 16px;
  font-weight: 500;
}

.success-modal .message .text {
  font-size: 14px;
  line-height: 1.3;
}

.success-modal strong {
  font-weight: 600;
}

.success-modal-cover .action {
  text-align: center;
  background: #eff9e5;
  padding: 20px;
  border-radius: 10px;
}

.success-modal-cover .action .input-field-instance {
  width: unset !important;
}

.success-modal-cover .action .text {
  font-size: 13px;
  font-weight: bold;
  font-family: Poppins;
  padding: 5px;
}

.success-modal-cover .action .beneficiary-name {
  width: 100%;
  background: #eff9e5;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  gap: 0px !important;
}

.success-modal-cover .action .beneficiary-name .save-btn {
  cursor: pointer;
  padding: 10px;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
}

.success-modal-cover .action .beneficiary-name input {
  background: #eff9e5;
}