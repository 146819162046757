.base-input {
  align-items: center;
  display: flex;
  gap: 28px;
  justify-content: center;
  position: relative;
  width: 260px;
  height: 60.2px;
}

.base-input .label {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: 192px;
}

.base-input .icon-instance-node-2 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.base-input .div {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  position: relative;
}

.base-input .placeholder-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.base-input .placeholder {
  color: var(--texttext-200);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.base-input .typer-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.base-input .typer {
  background-color: var(--texttext-200);
  height: 16px;
  position: relative;
  width: 1px;
}

.base-input .frame-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}
