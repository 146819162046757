.input-field {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 300px;
}

.input-field .text-wrapper {
  align-self: stretch;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin-top: -1px;
  position: relative;
}

.input-field .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.input-field .base-input-instance {
  flex: unset !important;
  flex-grow: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding: 30.1px 0;
  border: 0;
  outline: 0;
}

.input-field .base-input-instance.error {
  color: #D8000C;
}

.input-field .base-input-instance.success {
  color: #4F8A10;
}

.input-field .note {
  align-self: stretch;
  color: var(--texttext-200);
  font-family: var(--caption-font-family);
  font-size: var(--caption-font-size);
  font-style: var(--caption-font-style);
  font-weight: var(--caption-font-weight);
  letter-spacing: var(--caption-letter-spacing);
  line-height: var(--caption-line-height);
  position: relative;
}

.input-field .active {
  color: var(--texttext-500);
}

.input-field .disabled {
  color: var(--texttext-500);
}

.input-field .hover {
  color: var(--texttext-500);
}

.input-field .alert {
  color: var(--texttext-500);
}

.input-field .normal {
  color: var(--darkdark-200);
}

.input-field .warning {
  color: var(--texttext-500);
}

.input-field .success {
  color: var(--texttext-500);
}
