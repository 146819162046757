.description-field {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #D1FFA4;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  margin-top: -23px;
}

.description-field-text {
  width: 317px;
  color: black;
  font-size: 11px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 13px;
  word-wrap: break-word
}
